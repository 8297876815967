.file-description{
    display: block;
    width: 14vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font: normal normal normal 12px/19px Nunito Sans;
    color: #66676B;
}
.requestDetailsImg{
    height: 32px;
    height: 32px;
    margin-right: 5px;
}
.breakWord{
    word-break: break-all;
    display: inline-block;
    width: 23vw;
}
.status{
    
    font: normal normal normal 12px/19px Nunito Sans;
    border-radius: 37px;
    padding: 2px 10px;
    &.status-0{
        background: #dceff8 0% 0% no-repeat padding-box;
        color : #141489;
        border: 1px solid #141489;
    }

    &.status-1{
        background: #FFF6DD 0% 0% no-repeat padding-box;
        color : #FFAB00;
        border: 1px solid #FFAB00;
    }

    &.status-2{
        background: #eaf3e6 0% 0% no-repeat padding-box;
        color : #468525;
        border: 1px solid #468525;
    }

    &.status-3, &.status-4{
        background: #f7e5e9 0% 0% no-repeat padding-box;
        color : #b50d0d;
        border: 1px solid #b50d0d;
    }
}

.status-info{
    position: relative;
    top: 5px;
    left: 2px;
}
.wrapper{
    display: flex;
    align-items: center;
}
.title{
   font-weight: bold!important; 
}

.TableBodyText{
font: normal normal normal 12px/19px Nunito Sans;
color: #231F20;
}
.Actionbtn{
    font: normal normal bold 12px/16px Nunito Sans;
    color: #024EA2;
    background-color: transparent;
    border: none;
    outline: none;
    margin-right: 14px;
}

.disabled{
    cursor: not-allowed;
}
.myFilesContainer{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E6E6EB;
    margin:20px;

    .search-icon{
        width: 45px;
        height: 35px;
        position: absolute;
        z-index: 100;
        cursor: pointer;
    }

    tbody{
        th, td{
            padding: 8px 10px;
            border-bottom: 0px;
        }
    }
}

// .css-1hwn3g7-MuiTableCell-root.MuiTableCell-body, .css-17yzh7y-MuiTableCell-root.MuiTableCell-head{
//     border-bottom: 0px;
//     padding: 5px 10px ;
// }
.myFiles{
    font: normal normal bold 14px/15px Nunito Sans;
    color: #231F20;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root{
    font: normal normal normal 12px/16px Nunito Sans;
    letter-spacing: 0.11px;
    color: #66676B;
}
.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
    height:auto;
    padding:5px;
}
.css-1kcggdq-MuiInputBase-root{
    // background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E6E7E8;
    font: normal normal normal 12px/16px Nunito Sans!important;
    color: #66676B;
}
.MuiTableCell-head{
    border-top: 1px solid #E6E6EB;
    padding: 5px;
}

.no-files-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 67vh;
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 2rem;
        font: normal normal bold 14px/15px Nunito Sans;
        letter-spacing: 0px;
        color: #66676B;
        padding-bottom: 10vh;
    }
    .noFileText{
       margin: 8px auto 20px auto;
       font-weight: 400;
    }
    .addFileBtn{
        background:#F4F6FA 0% 0% no-repeat padding-box;
        color: #034EA2;
        padding: 8px 12px;
        font: normal normal bold 12px/16px Nunito Sans;
        border: none;
        outline: none;
        cursor: pointer;
    }
}