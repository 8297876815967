.TemplateDeleteModal {
  width: 500px;
  height: 224px;
}
.delete-modalTemp .MuiDialogActions-root {
  padding: 40px 14px 6px 16px;
  align-items: none;
  justify-content: space-between;
}
.delete-modal .MuiDialog-paperWidthSm {
  width: 28%;
  // padding: 0px 0 22px;
  padding: 0px 9px 20px 0px;
  border-radius: 8px;
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-top: solid 8px #1c75bb;
  overflow: hidden;
}
.delete-modal .MuiDialogContent-root {
  overflow: hidden;
  padding: 0px 24px;
}
.delete-modal .MuiDialogActions-root {
  padding: 8px 0 0 0;
  align-items: none;
  justify-content: flex-start;
}
.delete-modalheading {
  margin-bottom: -10px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #0a0a0a;
  padding-top: 9px;
}
.deleteBoxTemp {
  margin-top: -3px;
}
.deleteBoxTextTemp {
  padding-left: 10px;
}
.delete-modalSubHeading {
  padding-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #000;
  padding: 0px;
  margin-bottom: -10px;
}
.DeleteOnly {
  color: red;
}
.delete-modalContent {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  color: #332f2f;
  overflow: none;
}
.delete-modalContent1 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  color: #332f2f;
  overflow: none;
}
.delete-actionsTemp .btn-primary {
  border-color: #034ea2;
  background-color: #034ea2;
  border-radius: 3px;
  color: #fff;

  &:hover{
    border-color: #034ea2;
    background-color: #034ea2;
    border-radius: 3px;
    color: #fff;
  }
}
.delete-actionsTemp {
  padding-left: 17px !important;
}

.delete-actions {
  margin-top: 10px;
  // padding-left: 17px !important;
}

.delete-actions .btn-primary {
  background-color: #1c75bb;
  border-color: #1c75bb;
}
.delete-actionsTemp .btn-primary {
  border-color: #034ea2;
  background-color: #034ea2;
  border-radius: 3px;
}

.delete-actions .btn:disabled {
  opacity: 0.9 !important;
  background-color: #1c75bb;
}

.delete-actionsTemp .btn:hover {
  // color: white;
  // text-decoration: none;
  // background-color: #034ea2;
}

.no-btn {
  margin-right: 5px;
  color: #034ea2;
  font-weight: 600;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  //left: 0;
  align-items: left;
  font-family: "Nunito Sans", sans-serif;
}

.no-btn:focus {
  outline: none;
}

.delete-actions .btn-primary {
  margin-left: 42%;
  min-width: 35%;
}

.delete-actions .btn:hover {
  // color: white;
  // text-decoration: none;
}

.delete-modal .MuiDialogActions-root {
  padding: 8px 0 0 0;
  align-items: none;
  justify-content: flex-start;
}
.delete-modalTemp .MuiDialogActions-root {
  padding: 40px 14px 6px 16px;
  align-items: none;
  justify-content: space-between;
}
.delete-modalTemp .MuiDialog-paperWidthSm {
  width: 37%;
  border-top: solid 8px #034ea2;
}
.notificationText {
  color: #222222;
  margin-bottom: 5px;
  padding-left: -2%;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif !important;
}
.dialogbox-header{
  display: flex;
  justify-content: space-between;
}
.dialog-close{
  button{
    padding: 0;
  }
}
