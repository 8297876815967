.user-details{
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 50px;

    span{
        margin: 0 5px;
    }

    .logout{
        cursor: pointer;
    }
}