//Here define universal variables to be used through out the app

//Colors
$background-color: #ffff;
$text-primary : #034EA2;
$text-black : #000000;

//Fonts


//Font-family
$font-normal : "normal normal Nunito Sans;";

//Font-styles


//Font-weights

