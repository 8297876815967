.editor {
  width: 50%;
  // height: 90vh;
  // overflow-y: scroll;
}

.body,
html {
  margin: 0;
  height: 100%;
}

.block:hover {
  color: #d278c9;
}

.gjs-cv-canvas__frames {
  height: 90vh !important;
  overflow: scroll !important;
  margin-top: 3px !important;
  // overflow-y: scroll;
  // overflow-x: hidden;
}
.gjs-cv-canvas {
  top: 41px;
  height: calc(100% - 60px);
  border-bottom: 2px solid #e6e6ec;
  width: 100%;
}

.gjs-one-bg {
  // background-color: #9e9e9e !important;
  background-color: transparent !important;
}
.gjs-pn-commands {
  box-shadow: none !important;
  // border: 1px solid black !important;
  border-left: none !important;
}
.gjs-two-color {
  color: gray !important;
}
.gjs-pn-panel {
  padding: 6px !important;
  width: 111%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.gjs-pn-views {
  border-bottom: none;
}

.gjs-pn-views-container {
  display: none !important;
}

.gjsTopBar {
  padding: 11px;
  text-align: left;
  padding-left: 26px;
  text-align: left;
  border: 1px solid #e6e6ec;
  font-weight: 700;
  font-family: "Nunito Sans";
  font-size: 15px;
}

.sidebarDivGrapesjs {
  width: auto;
  background-color: white;
  max-width: 10%;
  width: 10%;
  position: relative;
  top: 41px;
}
.gjs-pn-buttons {
  display: none !important;
}
.gjs-pn-btn.gjs-pn-active {
  display: none;
}
.gjs-field input,
.gjs-field select,
.gjs-field textarea {
  background: #fff;
  color: black;
  font-size: 14px;
  border: 1px solid black;
}
.grapesjs-editor-popover {
  width: 250px;
  height: auto;
  border: 1px solid gray;
  position: absolute;
  right: 100%;
  top: 10px;
  z-index: 10;
  background-color: #e5e5e5;
}

.gjs-sm-sector-title {
  background: white;
}
.grapejs-selected:hover {
  outline: none; /* or set other styles as per your requirements */
}
.gjs-sm-sector-label {
  font-weight: 700;
}
.gjs-field {
  background: white !important;
  border: 1px solid #e6e6ec;
}
.gjs-field input,
.gjs-field select,
.gjs-field textarea {
  border: none !important;
}

.gjs-devices-c{
  display: none !important;
}
