.fileUploadContainer{
    width: 100%;
    display: flex;
    // height: 75vh;

    .uploadFile{
        height: inherit;
        width: 50%;
        border-right: 15px solid #f3f8fb;

        label{
            cursor: pointer;
        }
    }
    .addFile{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font: normal normal bold 16px/23px Nunito Sans;
        text-transform: capitalize;
        padding: 10px 15px ;
        color: #231F20;
        border-bottom: 1px solid #f6f6f6;
   
    }
    .labelText{
        font: normal normal normal 12px/16px Nunito Sans;
        color: #66676B;
        text-align: left;
        line-height: 2;
    }
    .fieldDiv{
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-top: 12px;

        span, label{
            font: normal normal normal 12px/16px Nunito Sans;
            color: #66676B;
            text-align: left;
            line-height: 2;
        }

        .required{
            color: red;
        }
        .error{
            margin-left: 70%;
            color: red;
        }

        .inputError{
            border: 1px solid red;
            outline: none;
        }

        .upload-file-dropdown{
            fieldset{
                border-color: black !important;
            }
        }
    }
    .fileDetails{
        padding: 5px 15px;
    }

    .inputField{
        padding: 10px;
        border: 1px solid #d4d4d4;
        font: normal normal normal 12px/16px Nunito Sans;

    }
    .uploadFileDiv{
        border: 2px dashed #034EA2;
        background: #F3F6F9 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 8px #00000029;
        width: 100%;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-transform: math-auto;
    }
    .uploadImg{
        margin-bottom: 20px;
    }
    .dragNDropText{
        font: normal normal normal 14px/18px Nunito Sans;
        color: #212121;
    }
}

.nextBtn{
    background: #034EA2 0% 0% no-repeat padding-box;
    color:white;
    padding: 8px 12px;
    font: normal normal bold 12px/16px Nunito Sans;
    text-transform: uppercase;
    border:none;
    outline: none;
    &[disabled]{
        background: #E6E7E8 0% 0% no-repeat padding-box;
        color : #66676B;
        cursor: not-allowed;
    }
}
.cancelBtn{
    font: normal normal bold 12px/16px Nunito Sans;
    color: #024EA2;
    background-color: transparent;
    border: none;
    outline: none;
    margin-right: 24px;
}
.backBtn{
    font: normal normal bold 12px/16px Nunito Sans;
    color: #024EA2;
    background-color: white;
    border: 1px solid #024EA2;
    padding: 5px 8px;
    outline: none;
    margin-right: 24px;

    &[disabled] {
        background: #e6e7e8 0% 0% no-repeat padding-box;
        color: #66676b;
        cursor: not-allowed;
        border: none;
      }
}
.dashboardTitle{
    display: flex;
    align-items: center;
    font: normal normal bold 17px/17px Nunito Sans;
    cursor: pointer;
  }
  .previewDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    flex-direction: column;
}
.dashboard-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: normal normal bold 17px/23px Nunito Sans;
    color: #66676B;
    text-transform: capitalize;
    padding: 20px 20px 0px 20px;
    .addFile{
        color: #231F20;
    }
    .uploadInputBox{
        position: absolute;
        top: 0px;
        background: transparent;
    }

    .action-buttons{
        display: flex;
        align-items: center;
        label{
            background-color: #f3f6f9;;
            padding-right: 5px;
        }
        div{
            font: normal normal bold 12px/16px Nunito Sans;
        }
    }
    
}
.pdf-viewer{
    width: 50%;
}
.fileName{
    font: normal normal bold 12px/16px Nunito Sans;
    letter-spacing: 0px;
    color: #231F20;
    text-transform: capitalize;
    margin:15px 0px;
    text-align: left;
}
.attachedFileBox{
    background: #DAE5F2 0% 0% no-repeat padding-box;
    font: normal normal bold 12px/16px Nunito Sans;
    border-radius: 4px;
    padding: 15px;
    position:relative;
    text-align: left;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
        margin-right: 10px;
    }

    .selectedFile{
        word-break: break-word;
        display: flex;
        padding-right: 25px;
    }

    div{
        display: flex;
    }
}
.ReplaceBtn{
    border: none;
    background: none;
    font-weight: bold!important;
    color: #034EA2;
    position: relative!important;
    right: 15px;
    text-transform: capitalize!important;

}