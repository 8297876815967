@import "../../Styles/variables.scss";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.body,
html {
  // overflow: hidden;
}

.Root {
  background-color: #f3f6f9;

  .container {
    margin: 0 15px;
    display: flex;
    justify-content: space-between;
    flex: 1;
    background-color: $background-color;
    overflow: hidden;
  }

  .stepperFooter {
    margin: 0 15px;
  }

  .stepper {
    height: 35px;
    display: flex;
    align-items: center;
    width: 40%;
    margin-left: 15px;
  }
  .nextBtn {
    background: #034ea2 0% 0% no-repeat padding-box;
    color: white;
    padding: 8px 12px;
    font: normal normal bold 12px/16px Nunito Sans;
    text-transform: uppercase;
    border: none;
    outline: none;
    &[disabled] {
      background: #e6e7e8 0% 0% no-repeat padding-box;
      color: #66676b;
      cursor: not-allowed;
    }
  }
  .cancelBtn {
    font: normal normal bold 12px/16px Nunito Sans;
    color: #024ea2;
    background-color: transparent;
    border: none;
    outline: none;
    margin-right: 24px;
  }
  .dashboardTitle {
    display: flex;
    align-items: center;
    font: normal normal bold 17px/17px Nunito Sans;
    cursor: pointer;
  }
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: normal normal bold 17px/23px Nunito Sans;
    color: #66676b;
    text-transform: capitalize;
    padding: 5px 20px 0px 20px;
    .addFile {
      color: #231f20;
    }
  }
}
.file-upload-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    margin-top: 5px;
    font: normal normal bold 12px/16px Nunito Sans;
    color: #66676b;
    position: relative;
    bottom: 30px;
  }
  width: 100vw;
}
