.mainDiv {
  background-color: #f3f6fa;
  display: flex;
  flex-direction: row;
  padding: 10px;
  width: 100vw;
  height: auto;
}
.metaDataContainer {
  height: 80vh;
  margin: 1%;
  background-color: white;
  width: 49%;
  font-family: "Nunito Sans";
  overflow-y: scroll;
}
.heading {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #231f20;
  padding: 15px;
  border-bottom: 1px solid #d5d5d5;
}
.box {
  padding: 15px;
  font-family: Nunito Sans;
}

#wrapper {
  height: 100% !important;
  width: 100% !important;
}

.enterDetails {
  font-family: Nunito Sans;
  font-size: 14px;
  color: #231f20;
  font-weight: 600;
}
.enterDetailsBox {
  display: flex;
  justify-content: space-between;

  p {
    font-family: Nunito Sans !important;
    font-size: 12px !important;
    color: #000000de;
  }
}
.emailName {
  font-family: Nunito Sans;
  font-size: 12px;
  margin: 10px 0 !important;
}
.metadata-dropdowns {
  margin-left: 0px !important;
  label {
    font-size: 13px !important;
    font-family: Nunito Sans !important;
    top: 1px !important;
    background-color: white !important;
    padding-right: 5px !important;
  }

  input {
    font-size: 13px !important;
    font-family: Nunito Sans !important;
    font-weight: 600;
  }

  div {
    div {
      font-size: 13px !important;
      font-family: Nunito Sans !important;
      font-weight: 600 !important;
    }
  }
}

.user-generated-html {
  position: relative;
  min-height: 100px;
  border: 1px solid transparent;
}

li {
  font-size: 13px !important;
  font-family: Nunito Sans !important;
}

.dropdown {
  width: 100%;
}

.metadata-dropdowns {
  min-width: 49% !important;
  margin: 5px 0 !important;
}

.inputError {
  input {
    border: 1px solid red;
    outline: none;
  }
}

.errorMessage {
  font-family: Nunito Sans;
  font-size: 14px;
  color: red;
  font-weight: 600;
}
