@import "./Styles/variables.scss";
html {
  background-color: #f3f8fb;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: $font-normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style-type: none;
}

button {
  cursor: pointer;
}

.custonScrollBar::-webkit-scrollbar {
  width: 2px !important;
  height: 2px;
}

.custonScrollBar::-webkit-scrollbar-thumb {
  border-radius: 1px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
  // background-image: url("../src/images/ScrolllllImage.jpg");
}

.customScrollBar::-webkit-scrollbar {
  background-color: #fff;
}

.customScrollBar::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

.customScrollBar::-webkit-scrollbar-thumb {
  border-radius: 1px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(209, 69, 69, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.custonScrollBar::-webkit-scrollbar {
  background-color: #fff;
}
