@import "./Styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap");
.App {
  background-color: $background-color;
}
.spinner {
  width: 100%;
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.section-container {
  outline-offset: unset !important;
}

.gjs-sm-sector-label {
  font-family: "Nunito Sans";
}

.gjs-four-color {
  color: black !important;
  font-family: "Nunito Sans";
}

.gjs-two-color {
  color: black !important;
  font-family: "Nunito Sans";
}
.grapesjs-editor-popover {
  border: none !important;
  box-shadow: 0px 0px 8px #00000029;
  background: #ffff;
}
.gjs-sm-properties {
  background: #ffff;
  max-height: 75vh;
  overflow: auto;

  .gjs-sm-stack #gjs-sm-add {
    color: black;
  }
}

.gjs-rte-actionbar {
  background: #fff;
}
.loader {
  width: 40px;
  height: 40px;
  border: 3px solid #1976d2;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
