.parentContainer {
  display: flex;
  border: 1px solid rgb(217, 216, 226);
  // margin: 0 auto;
  // width: 746px; //746
  width: 100%; // changed this width for edetail and emailer width
}
.lhs {
  background: #edf1f4;
  width: 8%;
}
.lhsItems {
  border: 1px solid rgb(224 223 239);
  height: 80px;
  padding: 20px;
  cursor: pointer;
  font-family: "Nunito Sans";
  font-size: small;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    border: none;

    &:hover {
      border: none;
    }
  }

  .parent-navigation {
    // border: 1px solid #afc9e7;
    list-style-type: none;
    margin: 0;
    padding: 2px 0;
    height: 32px;
  }
  .pageCount {
    color: #000;
    font-size: 0.7rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.4;
    // margin-right: 6px;
    padding-top: 4px !important;
    padding-top: 5px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .jupmToPage {
    border: none;
    flex: 1 1;
    font-size: 0.7rem;
    font-weight: 600;
    text-align: right;
    width: 15px;
    background-color: #edf1f4;
    border: none;
    outline: none;
    position: relative;
    top: -1px;
  }

  .page-text {
    display: inline-block;
    margin-left: 0.25rem;
  }

  .pageTotalCount {
    color: #000;
    font-size: 0.7rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.4;
    margin-right: 6px;
    padding-top: 4px !important;
    padding-top: 5px;
    text-align: left;
  }

  .new-slide-right-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .new-slide-left-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .disableNavigate {
    width: 30px !important;
    height: 30px !important;
    opacity: 0.2;
    cursor: default !important;
  }

  .action-btn {
    color: black;
    font: normal normal normal 11px / 11px Nunito Sans;
    letter-spacing: 0;
    margin: 5px;
    text-align: center;
    text-transform: math-auto;
  }

  .action-button {
    align-items: center;
    background-color: #0a66c2;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
      "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
      "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Lucida Grande", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    max-width: 480px;
    min-height: 40px;
    min-width: 0px;
    overflow: hidden;
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
      box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
      color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
  }

  .action-button:hover,
  .action-button:focus {
    background-color: #16437e;
    color: #ffffff;
  }

  .action-button:active {
    background: #09223b;
    color: rgb(255, 255, 255, 0.7);
  }

  .action-button:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.08);
    color: rgba(0, 0, 0, 0.3);
  }
}
.rhs {
  position: relative;
  overflow: scroll;
  // height: 480px;
  height: calc(100vh - 120px);
  // .section-border {
  //   z-index: 100;
  // }
  width: 100%;
  // display: flex;
  // justify-content: center;
}

.progress-bar {
  display: flex;
  height: 75vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.modify-image-contextMenu {
  width: 130px;
  text-align: left;
  cursor: pointer;
  font-family: "Nunito Sans";
  // text-transform: uppercase;
  li {
    padding: 10px 10px;
    background-color: rgb(227, 230, 230);
    border-bottom: 1px solid #b3a6a6;
  }

  li:hover {
    background-color: #d7f0f0;
  }
  .leftAligned {
  }

  // li:nth-child(odd){
  //   background-color: rgb(243, 245, 245);
  // }
  // li:nth-child(even){
  //   background-color: rgb(238, 233, 233);
  // }
}

.customPanel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 50px;
  z-index: 100;

  button {
    border-radius: 50%;
    border: 1px solid skyblue;
    padding: 0px;
    margin: 0 5px;
    background: #034ea2 0% 0% no-repeat padding-box;
    color: white;
  }

  span {
    width: 30px;
  }
  .image-rhs {
    display: flex;
    width: 600px; //100%
  }
}
