.css-1hv8oq8-MuiStepLabel-label.Mui-active{
    color: #034EA2 !important;
    font: normal normal bold 12px/16px Nunito Sans;
    font-size: 12px;
    font-weight: bold;
}
.css-1hv8oq8-MuiStepLabel-label{
    font: normal normal bold 12px/16px Nunito Sans;
    font-family: Nunito Sans;
    letter-spacing: 0px;
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.6);
}