.dashboard-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: normal normal bold 17px/23px Nunito Sans;
    color: #231F20;
    text-transform: capitalize;
    padding: 20px 20px 0px 20px;
}
.addBtn{
    background: #034EA2 0% 0% no-repeat padding-box;
    color:white;
    padding: 8px 12px;
    font: normal normal bold 12px/16px Nunito Sans;
    text-transform: uppercase;
    border:none;
    outline: none;
}