.preview {
  width: 50%;
  border-right: 15px solid #f3f8fb;

  .selectedFile {
    height: 90vh;
    overflow-y: scroll;
  }

  .preview-topbar {
    width: 100%;
    height: 45px;
    background: #2162d51a;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .generate-layoutBtn {
      border-radius: none;
      margin-right: 10px;
      height: 32px;
      padding: 8px;
    }

    .new-slide-right-icon {
      width: 30px !important;
      height: 30px !important;
    }

    .new-slide-left-icon {
      width: 30px !important;
      height: 30px !important;
    }

    .disableNavigate {
      width: 30px !important;
      height: 30px !important;
      opacity: 0.2;
      cursor: not-allowed !important;
    }
  }
}

.mainCanvasContainerWrapper {
  height: calc(100vh - 60px);
  overflow-y: scroll;
}
.mainCanvasContainer {
  position: relative;
  // height: 85vh;
  // overflow-y: scroll;
  // border: 1px solid black;
  margin-bottom: 10px;
  width: 600px;

  .static-message-html {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #66676b;
    margin: auto;
    font: normal normal bold 12px / 16px Nunito Sans;

    .navigate-back {
      color: #024ea2;
      cursor: pointer;
    }
  }
}
.formClass {
  padding: 10px;
  background-color: #ffffff;
  padding-bottom: 8px;
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.extractFont {
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  color: #231f20;
}
.contextMenuButtons {
  border: none;
  background: none;
  // border-bottom: 1px solid black;
  cursor: pointer;
}
.buttonsContainer {
  display: flex;
  justify-content: center;
  padding: 3px;
  padding-left: 14px;
}
.customButtons {
  height: 22px;
  color: #fff;
  border-radius: 0;
  border: none;
  font-size: 12px;
  padding: 2px;
  background: #034ea2;
  margin-right: 5px;
}

.contextMenu {
  width: 130px;
  text-align: left;
  cursor: pointer;
  // text-transform: uppercase;
  li {
    padding: 10px 10px;
  }

  li:hover {
    background-color: #e3e9e9;
  }
}

.fileLoadProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sidebarDiv {
  width: auto;
  background-color: white;
  max-width: 10%;
  width: 10%;
}
.IconDiv {
  border: 1px solid #e6e6ec;
  padding: 6px 3px 0px 3px;
  button {
    display: flex;
    flex-direction: column;
    border-radius: none !important;
    padding: none !important;
  }
}
.sideLabel {
  text-align: center;
  font: normal normal normal 8px/11px Nunito Sans;
  letter-spacing: 0px;
  color: #252529;
  text-transform: math-auto;
  margin: 5px;
}
.page-0 {
  border: none !important;
}

.threshold {
  input {
    width: 50%;
    padding: 8px;
    border: 1px solid #d4d4d4;
    font: normal normal normal 12px/16px Nunito Sans;
    margin-left: 25%;
    border-radius: 5px;
  }

  h2 {
    font: normal normal bold 17px/17px Nunito Sans;
  }

  p {
    margin-top: 5px;
    color: red;
  }
}

.htmlPreview-pageCount {
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
