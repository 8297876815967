
@import "../../Styles/variables.scss";
.toolbar{
    padding: 0 20px;

    .user-name{
        font-family: $font-normal;
        color: $text-black;
        font-size: 14px;
        font-weight: 600;
        font: normal normal 600 14px/15px Nunito Sans;
        color: #000000;
        margin-left: 7px;
    }
}